export default {
  namespaced:true,
  state:()=> ({
    cachesArray:[],
  }),
  mutations: {
    // 添加需要缓存组件
    addCaches(state,item) {
      if(item.key == '0') {
        state.cachesArray = []
        return
      }
      state.cachesArray.push(item)
    },
    // 移除缓存组件
    removeCaches(state,item) {
      const index = state.cachesArray.filter(_item=>_item.name == item.name)
      state.cachesArray.splice(state.cachesArray.findIndex(_item=>_item.name ==item.name),1)
    },

    changeCaches(state, item) {
      state.cachesArray = item
    }
  },
  actions: {
    addComponents({commit}, item) {
      commit('addCaches', item)
    },
    removeComponents({commit}, item) {
      commit('removeCaches', item)
    },
    refreshComponents({commit}, item) {
      commit('refreshCaches', item)
    }
  }
}