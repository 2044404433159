export default {
    path: "/client",
    name: "client",
    redirect: "/client/client_manage",
    component: () => import("@/layouts/BaseLayout"),
    children: [
          {
            name:'client',
            path: "client",
            meta: { title: "客户信息", permission: "client" },
            component: () => import("@/views/client/client/index"),
          },
          {
            name: 'follow',
            path: "follow",
            meta: { title: "客户跟进", permission: "follow" },
            component: () => import("@/views/client/follow/index"),
          },
          {
            name: 'analyse',
            path: "analyse",
            meta: { title: "客户分析", permission: "analyse" },
            component: () => import("@/views/client/analyse/index"),
          },
          {
            name: 'business_opportunity',
            path: "business_opportunity",
            meta: { title: "商业机会", permission: "business_opportunity" },
            component: () => import("@/views/client/businessOpportunity/index")
          },
           {
            name: 'agent_clients',
            path: "agent_clients",
            meta: { title: "经销旗下", permission: "agent_clients" },
            component: () => import("@/views/client/agent_client/index.vue")
          }
    ],
};
