export default {
  namespaced:true,
  state: () => ({
     number:'',
     index:'',
     timeout:null,
  }),
  mutations: {
    setNumber(state, item) {
      // console.log(state,item);
      state.number = item
    },

    setIndex(state,item) {

      state.index = item
    },

    setTimeout(state,item){
      state.timeout = item
    }
  }
  
}