import { getInfo } from "@/api/user"; 

export default {
  namespaced:true,
  state: () => ({
    approval:[],
    is_manager:false,
    restrict_brand:'',
    id:'',
  }),
  mutations: {
    setApproval(state,item) {
      // console.log('item',item);
 
      if(item.approval==null) {
        state.approval = []
      }else{
        state.approval = item.approval
      }
      state.is_manager = item.is_manager
      state.restrict_brand = item.restrict_brand
      state.id = item.id
      // console.log('state',state.approval);
    }
  },
  actions: {
    async getApproval({commit}) {
      await getInfo().then(res=>{
          // console.log('res',res);
          commit('setApproval',res)
      }).catch(err=>{

            
      })
     }
   
  }
  
}