export default {
  path: "/sale",
  name: "sale",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/sale/sale_create",
  children: [
    {
      name: 'sale_create',
      path: "sale_create",
      meta: { title: "销售开单", permission: "sale_create", keepAlive:false },
      component: () => import("@/views/sale/saleCreate/index"),
    },
    {
      path: "sale_record",
      name:'sale_record',
      meta: { title: "销售记录", permission: "sale_record", keepAlive:false},
      component: () => import("@/views/sale/saleRecord/index"),
    },
    {
      path: "sale_managerApproval_record",
      name:'sale_managerApproval_record',
      meta: { title: "审销记录", permission: "sale_managerApproval_record", keepAlive:false},
      component: () => import("@/views/sale/saleRecord/managerApprovalIndex.vue"),
    },
    //  {
    //   path: "sale_details",
    //   meta: { title: "销售明细", permission: "sale_details" },
    //   component: () => import("@/views/sale/saleDetails/index"),
    // },
    {
      name:'sale_record_detail',
      path: "sale_record_detail",
      meta: {
        title: "销售记录详情",
        notMenu: true,
        permission: "sale_record",
      },
      component: () => import("@/views/sale/saleRecordDetail/index"),
    },
    {
      name:'sale_return_create',
      path: "sale_return_create",
      meta: { title: "销售退货", permission: "sale_return_create" },
      component: () => import("@/views/sale/saleReturnCreate/index"),
    },
    {
      name:'sale_return_record',
      path: "sale_return_record",
      meta: { title: "退货记录", permission: "sale_return_record", keepAlive:false },
      component: () => import("@/views/sale/saleReturnRecord/index"),
    },
    {
      name:'sale_return_detail',
      path: "sale_return_detail",
      meta: {
        title: "退货记录详情",
        notMenu: true,
        permission: "sale_return_record",
      },
      component: () => import("@/views/sale/saleReturnDetail/index"),
    },
  ],
};
