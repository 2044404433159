export default {
  path: "/assets",
  name: "assets",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/assets/fixed_asset",
  children: [
    {
      path: "fixed_asset",
      name: 'fixed_asset',
      meta: { title: "固定资产", permission: "fixed_asset" },
      component: () => import("@/views/assets/fixed_asset/index"),
    },
  ],
};

