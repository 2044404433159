export default {
  path: "/",
  name: "team",
  component: () => import("@/layouts/BaseLayout"),
  children: [
    {
      path: "team",
      name: "team",
      meta: { title: "Team配置", onlyAdmin: true },
      component: () => import("@/views/team/Team"),
    },
    // {
    //   path: "team/register",
    //   name: "team/register",
    //   meta: { title: "Team注册", toAll: true },
    //   component: () => import("@/views/team/TeamRegister"),
    // },
  ],
};
