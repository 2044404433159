import request from '@/utils/request';
//产品分类
export function goodsClassificationListNew(params) {
  return request({ url: `/primary_goods_categories/`, method: 'get', params })
}
export function goodsClassificationDestroyNew(data) {
  return request({ url: `/primary_goods_categories/${data.id}/`, method: 'delete', data })
}
export function goodsClassificationCreateNew(data) {
  return request({ url: `/primary_goods_categories/`, method: 'post', data })
}
export function goodsClassificationUpdateNew(data) {
  return request({ url: `/primary_goods_categories/${data.id}/`, method: 'put', data })
}


export function secondGoodsClassificationListNew(params) {
  return request({ url: `/second_goods_categories/`, method: 'get', params })
}
export function secondClassificationDestroyNew(data) {
  return request({ url: `/second_goods_categories/${data.id}/`, method: 'delete', data })
}
export function secondClassificationCreateNew(data) {
  return request({ url: `/second_goods_categories/`, method: 'post', data })
}
export function secondClassificationUpdateNew(data) {
  return request({ url: `/second_goods_categories/${data.id}/`, method: 'put', data })
}


// 产品分类
export function goodsClassificationList(params) {
  return request({ url: `/goods_categories/`, method: 'get', params })
}
// 批量删除
export function goodsClassificationDestroyBatch(data) {
  return request({ url: `/goods_categories/${data}/`, method: 'delete', data })
}

//商品库存数量获取
export function goodsInventory(params) {
  return request({ url: `/inventories/`, method: 'get', params })
}

export function goodsClassificationCreate(data) {
  return request({ url: `/goods_categories/`, method: 'post', data })
}

export function goodsClassificationUpdate(data) {
  return request({ url: `/goods_categories/${data.id}/`, method: 'put', data })
}

export function goodsClassificationDestroy(data) {
  return request({ url: `/goods_categories/${data.id}/`, method: 'delete', data })
}

export function goodsClassificationBatchDestroy(ids) {
  return request({ url: `/api/goods_categories/batch_destroy/`, method: 'post', data: { ids } })
}


// 产品信息
export function goodsInformationList(params) {
  return request({ url: `/goods/`, method: 'get', params })
}

export function goodsInformationCreate(data) {
  return request({ url: `/goods/`, method: 'post', data })
}

export function goodsInformationUpdate(data) {
  return request({ url: `/goods/${data.id}/`, method: 'put', data })
}

export function goodsInformationDestroy(data) {
  return request({ url: `/goods/${data.id}/`, method: 'delete', data })
}

// 产品单位
export function goodsUnitList(params) {
  return request({ url: `/goods_units/`, method: 'get', params })
}

export function goodsUnitCreate(data) {
  return request({ url: `/goods_units/`, method: 'post', data })
}

export function goodsUnitUpdate(data) {
  return request({ url: `/goods_units/${data.id}/`, method: 'put', data })
}

export function goodsUnitDestroy(data) {
  return request({ url: `/goods_units/${data.id}/`, method: 'delete', data })
}
//应用方向

export function goodsApplicationDirectionList(params) {
   return request({ url: `/application_direction/`, method: 'get', params })
}

export function goodsApplicationDirectionCreate(data) {
   return request({ url: `/application_direction/`, method: 'post', data})
}

export function goodsApplicationDirectionUpdate(data) {

   return request({ url:`/application_direction/${data.id}/`, method: 'put',data }) 
}

export function goodsApplicationDirectionDestroy(data) {
   return request({ url:`/application_direction/${data.id}/`, method:'delete',data})
}


// 产品品牌
export function goodsBrandList(params) {
  return request({ url: `/goods_brands/`, method: 'get', params })
}

export function goodsBrandCreate(data) {
  return request({ url: `/goods_brands/`, method: 'post', data })
}

export function goodsBrandUpdate(data) {
  return request({ url: `/goods_brands/${data.id}/`, method: 'put', data })
}

export function goodsBrandDestroy(data) {
  return request({ url: `/goods_brands/${data.id}/`, method: 'delete', data })
}
export function batchsList(params) {
  return request({ url: `/batchs/`, method: 'get', params })
}

//产品套餐
export function comboList(params) {
  return request({ url: `/combo_orders/`, method: 'get', params })
}
export function comboCreate(data) {
   return request({ url: `/combo_orders/`, method: 'post', data})
}
// 产品套餐number
export function comboNumber(params) {
  return request({ url: `/combo_orders/number/`, method: 'get', params })
}
export function comboDetail(params) {
  return request({ url: `/combo_orders/${params.id}/`, method: 'get', params })
}
export function comboOption(params) {
  return request({ url: `/combo/options/`, method: 'get', params })
}

export function comboIs_active(data) {
  return request({ url: `/combo_orders/${data.id}/is_active/`, method: 'post', data})
}