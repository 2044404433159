
const Cookies = require('js-cookie')

// console.log(Cookies.set);
// 浏览器的派发事件
// 监听如果修改用户名之后,用户名可以实时更新
export function dispatchEventStroage() {
  const signSetItem = Cookies.set
  Cookies.set= function(key, val) {
      let setEvent = new Event('setItemCookieEvent')
      setEvent.key = key
      setEvent.newValue = val
      window.dispatchEvent(setEvent)
      signSetItem.apply(this, arguments)
  }
}


// 监听修改缓存数组发生变化的函数
export function dispatchEventCaches() {
    const signSetItem = localStorage.setItem
    localStorage.setItem = function(key, val) {
      let setEvent = new Event('setItemCachesEvent')
      setEvent.key = key
      setEvent.newValue = val
      window.dispatchEvent(setEvent)
      signSetItem.apply(this, arguments)
    }
}
