// import Vue from 'vue'
import Vuex from 'vuex'
// Vue.use(Vuex)
import user from './modules/user'
import system from './modules/system'
import team  from './modules/team'
import approval from './modules/approval'
import cacheComponents from './modules/cacheComponents'
import sale from './modules/sale'
export default new Vuex.Store({
  modules: {
    user,
    system,
    team,
    approval,
    cacheComponents,
    sale
  },
})