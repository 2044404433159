const { saleOrderCreate,saleOrderUpdate,saleOrderList,saleOrderDetail } = require('@/api/sale')
const { userOption,clientsOption, warehousesOption,inventoriesOption,accountsOption,companyOption, goodsOption } = require('@/api/option')
const { warehouseList,settlementAccountList,companyList,clientList } = require('@/api/basicData')
const { ClientsContactList } = require('@/api/client')
const { getInfo } = require("@/api/user")
const { getSaleOrderNumber } = require("@/api/data")
//  产品种类
const { goodsClassificationList, comboOption, goodsClassificationListNew } = require("@/api/goods");
export default {
  namespaced:true,
  state:()=> ({
    formData:{
      warehouseItems:[],//销售开单初始加载的仓库列表数据
      clientItems:[]//销售开单初始加载客户列表数据
    },
    goodData:{},//产品选择组件的接口数据
    defaultForm:{},//销售开单的默认值
    hoverIndex:null,//鼠标在产品table中悬浮的索引值
    hoverAccountIndex:null,//鼠标在结算账户table中悬浮的索引值
    is_combo:null,//table的record是否包含套餐判断boolean
    spinning:false,//产品modal加载的状态
    sellerSelectOption:[],//产品价格类型选择的对象
    goods_sale_price:'',
    hoverbillsIndex:null,//鼠标在发票信息table中的悬浮的索引值
    clientsContactItems:null,//开启客户联系人的列表数据
  }),
  actions: {
    // 表单初始数据加载
    async getFormData( { commit } ) {
      try {
        // 出货仓库
        const warehousePromise = new Promise((resolve, reject) => {
          warehousesOption({ page_size: 9999999, is_active: true }).then(res => {
            resolve(res?.results)
          })
        })
        // 销售企业
        const companyPromise = new Promise((resolve, reject) => {
          companyOption({ page_size: 999999, is_active: true }).then(res => {
            resolve(res?.results)
          })
        })
        // 客户
        const clientPromise = new Promise((resolve, reject) => {
          clientsOption({ page_size: 999999, is_active: true }).then(res => {
            resolve(res?.results)
          })
        })
        // 经手人
        const handlerPromise = new Promise((resolve, reject) => {
          userOption({ page_size: 999999, is_active: true }).then(res => {
            resolve(res?.results)
          })
        })
        // 销售员
        const sellerPromise = new Promise((resolve, reject) => {
          userOption({ page_size: 999999, is_active: true }).then(res => {
            resolve(res?.results)
          })
        })
        // 制单人
        const creatorPromise = new Promise((resolve, reject) => {
          userOption({ page_size: 999999, is_active: true }).then(res => {
            resolve(res?.results)
          })
        })
        // 账户
        const accountPromise = new Promise((resolve, reject) => {
          accountsOption({ page_size: 999999, is_active: true }).then(res => {
            resolve(res?.results)
          })
        })
        // 用户信息
        const getInfoPromise = new Promise((resolve, reject) => {
          getInfo().then(res => {
            resolve(res)
          })
        })
        // 产品信息
        const getGoodsPromise = new Promise((resolve, reject) => {
          goodsOption({ page_size: 999999, is_active: true }).then(res => {
            resolve(res?.results)
          })
        })
        // 产品开单编号信息
        const goodsNumber = new Promise((resolve, reject) => {
          getSaleOrderNumber({ page_size: 999999, is_active:true }).then(res => {
            resolve(res?.number)
          })
        })        
        const [number, warehouseItems, companyItems, clientItems, handlerItems, sellerItems, creatorItems, accountItems, userInfo, goodItems ] = await Promise.all([goodsNumber, warehousePromise, companyPromise, clientPromise, handlerPromise, sellerPromise, creatorPromise, accountPromise, getInfoPromise, getGoodsPromise])
        
        commit( 'getFormData', {number, warehouseItems, companyItems, handlerItems, clientItems, sellerItems, creatorItems, accountItems, userInfo, goodItems} )
      }catch(err) {
        console.log('err:' + err);
      }
    },
    //产品选择组件数据加载
    async getGoodData( { commit }, { comboForm, goodForm, type, primary_category, second_category } ) {
      try {
        // 产品种类
        const goodsTypePromise = new Promise((resolve, reject) => {
          goodsClassificationListNew({ page_size: 9999999, is_active: true }).then(res => {
            resolve(res?.results)
          })
        })
        let goodsPromise = null
        let combosPromise  = null
        // 判断type是产品还是套餐
        if( type == 0 ) {
           // 产品列表
           goodsPromise = new Promise((resolve, reject) => {
             inventoriesOption({page: goodForm.page, page_size:goodForm.page_size ,warehouse: goodForm.warehouse, is_active: true, search:goodForm.search, primary_category, second_category}).then(res => {
               resolve(res)
             })
           })
        }else if( type == 1) {
           // 套餐列表
           combosPromise = new Promise((resolve, reject)=> {
              comboOption({page: comboForm.page, page_size:comboForm.page_size ,warehouse: comboForm.warehouse, is_active: true, search:comboForm.search, primary_category, second_category}).then(res => {
                resolve(res)
              })
           })
        }
        const [ goodsTypeItems, goodsItems, combosItems ] = await Promise.all([goodsTypePromise, goodsPromise, combosPromise]) 
        commit( 'getGoodData', { goodsTypeItems, goodsItems, combosItems } )
      }catch(err) {
        console.log('err:' + err);
      }
    },
    // 获取客户联系人
    async getClientsContact({ commit }, param) {
      try {
        if(!param.client) return
        const clientItems  = await ClientsContactList(param) 
        commit('getClientsContact', clientItems.results)
      }catch(err) {
        console.log('err:' + err);
      }
    }
  },
  mutations: {
    getFormData( state, item ) {
      const { warehouseItems, companyItems, userInfo, number } = item
      // 默认仓库
      const defaultwarehouse = warehouseItems.find(i=>i.is_default===true)
      // 默认企业
      const defaultcompany = companyItems.find(i=>i.is_default===true)
      // 默认经手人
      const defaulthandler = userInfo.id
      //默认制单人
      const defaultcreator = userInfo.id

      state.defaultForm = {number, warehouse:defaultwarehouse?.id, company: defaultcompany?.id, handler:defaulthandler, creator:defaultcreator, discount: 100, settlement_method: "invoice",  handle_time: moment().format('YYYY-MM-DD HH:mm:ss')}
      state.formData = item
      // console.log('表单', state.formData);
    },
    getGoodData( state, item ) {
      state.goodData = item  
      // console.log('产品加载', state.goodData);
      setTimeout(()=>{
        state.spinning = false
      }, 1000)
      // Vue.set(state, 'spinning', false);
      // console.log('加载完成', state.spinning);
    },
    // 保存产品table中悬浮值
    saveHoverIndex( state, item ) {
      state.hoverIndex = item
    },
    // 保存结算账户table中索引值
    saveHoverAccountIndex( state, item ) {
      state.hoverAccountIndex = item
    },
    // 保存发票table中的索引值
    saveHoverbillsIndex( state, item ) {
       state.hoverbillsIndex = item
    },
    // 修改产品的modal状态
    changeSpinning( state, item ) {
      Vue.set(state, 'spinning', item);
      // console.log('加载中1', state.spinning);
    },
    // 获取价格权限
    changeSelectOption(state, flag) {
      // console.log('flag', flag);
      switch (flag){
        case "retail_price": state.sellerSelectOption=[
                              {value:0,name:'目录价'},
                              {value:6,name:'销售自定价'},
                            ];
                            break;
        case "level_price1": state.sellerSelectOption=[
                              {value:0,name:'目录价'},
                              {value:1,name:'终端价'},
                              {value:6,name:'销售自定价'},
                             ];
                            break;
        case "level_price2": state.sellerSelectOption=[
                              {value:0,name:'目录价'},
                              {value:1,name:'终端价'},
                              {value:2,name:'销售员价'},
                              {value:6,name:'销售自定价'},
                            ];
                            break;
        case "level_price3": state.sellerSelectOption=[
                              {value:0,name:'目录价'},
                              {value:1,name:'终端价'},
                              {value:2,name:'销售员价'},
                              {value:3,name:'经销商价'},
                              {value:6,name:'销售自定价'},
                            ];
                            break;
        case "level_price4": state.sellerSelectOption=[
                              {value:0,name:'目录价'},
                              {value:1,name:'终端价'},
                              {value:2,name:'销售员价'},
                              {value:3,name:'经销商价'},
                              {value:4,name:'一级代理商价'},
                              {value:6,name:'销售自定价'},
                            ];
                            break;
        case "level_price5": state.sellerSelectOption=[
                              {value:0,name:'目录价'},
                              {value:1,name:'终端价'},
                              {value:2,name:'销售员价'},
                              {value:3,name:'经销商价'},
                              {value:4,name:'一级代理商价'},
                              {value:5,name:'总监红线价'},
                              {value:6,name:'销售自定价'},
                            ];
                            break;
      }
    },
    // 修改产品销售单价
    changeSalePrice(state, item) {
      // console.log('选择类型', item.priceType);
      switch(item.priceType) {
        case 0: state.goods_sale_price = { price:item.retail_price, disbled:true };       
          break;
        case 1: state.goods_sale_price = { price:item.level_price1, disbled:true };
          break;
        case 2: state.goods_sale_price = { price:item.level_price2, disbled:true };
          break;
        case 3: state.goods_sale_price = { price:item.level_price3, disbled:true };
          break;
        case 4: state.goods_sale_price = { price:item.level_price4, disbled:true };
          break;
        case 5: state.goods_sale_price = { price:item.level_price5, disbled:true };
          break;
        case 6: state.goods_sale_price = { price:item.sales_price, disbled:false };
          break;
      }
    },
    // 开启客户联系人之后，获取客户联系人
    getClientsContact(state, item) {
      state.clientsContactItems = item
    }
  }
}