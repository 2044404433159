export default {
  path: "/basicData",
  name: "basicData",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/basicData/client_classification",
  children: [
    {
      name: 'company',
      path: "company",
      meta: { title: "企业信息", permission: "company" },
      component: () => import("@/views/basicData/company/index"),
    },
    {
      name: 'department',
      path: "department",
      meta: { title: "部门管理", permission: "department" },
      component: () => import("@/views/basicData/department/index"),
    },
    {
      name: 'supplier',
      path: "supplier",
      meta: { title: "供应商管理", permission: "supplier" },
      component: () => import("@/views/basicData/supplier/index"),
    },
    {
      name: 'warehouse',
      path: "warehouse",
      meta: { title: "仓库管理", permission: "warehouse" },
      component: () => import("@/views/basicData/warehouse/index"),
    },
    {
      name: 'settlement_account',
      path: "settlement_account",
      meta: { title: "结算账户", permission: "settlement_account" },
      component: () => import("@/views/basicData/settlementAccount/index"),
    },
    {
      name: 'revenue_expenditure_items',
      path: "revenue_expenditure_items",
      meta: {
        title: "收支项目",
        permission: "revenue_expenditure_items",
      },
      component: () =>
        import("@/views/basicData/revenueExpenditureItems/index"),
    },
  ],
};
