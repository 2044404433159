export default {
  path: '/report',
  name: 'report',
  component: () => import('@/layouts/BaseLayout'),
  redirect: '/report/sale_report',
  children: [
  // 运营大盘
    {
      name:'operation_market',
      path:'operation_market',
      meta:{ title:'运营大盘', permission:'operation_market'},
      component:()=>import('@/views/report/operationMarket/index'),
    },
    {
      name: 'sale_report',
      path: 'sale_report',
      meta: { title: '销售报表', permission: 'sale_report', keepAlive:false },
      component: () => import('@/views/report/saleReport/index'),
    },
    {
      name: 'sample_report',
      path: "sample_report",
      meta: { title: "样品报表", permission: "sample_report" },
      component: () => import("@/views/goodsSample/sampleReport/index"),
    },
    {
      name: 'purchase_report',
      path: 'purchase_report',
      meta: { title: '采购报表', permission: 'purchase_report', keepAlive:false },
      component: () => import('@/views/report/purchaseReport/index'),
    },
    {
      name: 'income_expense_statistics',
      path: 'income_expense_statistics',
      meta: { title: '收支统计', permission: 'income_expense_statistics', keepAlive:false },
      component: () => import('@/views/report/incomeExpenseStatistics/index'),
    },
    {
      name: 'batch_report',
      path: 'batch_report',
      meta: { title: '批次报表', permission: 'batch_report' },
      component: () => import('@/views/report/batchReport/index'),
    },
  ],
}