import request from '@/utils/request';

// 销售开单
export function saleOrderCreate(data) {
  return request({ url: `/sales_orders/`, method: 'post', data })
}

// 销售记录
export function saleOrderList(params) {
  return request({ url: `/sales_orders/`, method: 'get', params })
}

// 销售记录
export function saleOrderInfo(params) {
  return request({ url: `/sales_orders/${params.id}/`, method: 'get', params })
}


// 销售记录修改
export function saleOrderPutUpdate(data) {
  return request({ url: `/sales_orders/${data.id}/`, method: 'put', data })
}


// 销售单据

export function saleOrderNormal(params) {
  return request({ url: `/sales_orders/normal_man/`, method: 'get', params })
}

// 销售记录修改
export function saleOrderUpdate(data) {
  return request({ url: `/sales_orders/${data.id}/`, method: 'PATCH', data })
}

// 主管审批
export function salesApprovalRecord (data) {
  return request({ url: `/sales_orders/${ data.id }/price_is_rational/`, method: 'post', data })
}



// 销售记录详情
export function saleOrderDetail(params) {
  return request({ url: `/sales_orders/${params.id}/`, method: 'get', params })
}

// 销售记录录作废
export function saleOrdersVoid(data) {
  return request({ url: `/sales_orders/${data.id}/void/`, method: 'post', data })
}

// 销售记录审批
export function saleOrdersStatus(data) {
  return request({
    url: `/sales_orders/${data.id}/status/`, method: 'post', data: {
      status: data.status + ''
    }
  })
}

// 销售退货记录审批
export function saleReturnOrdersStatus(data) {
  return request({
    url: `/sales_return_orders/${data.id}/status/`, method: 'post', data: {
      status: data.status + ''
    }
  })
}

// 销售退货
export function saleReturnOrderCreate(data) {
  return request({ url: `/sales_return_orders/`, method: 'post', data })
}

// 销售退货记录
export function saleReturnOrderList(params) {
  return request({ url: `/sales_return_orders/`, method: 'get', params })
}
// 销售退货修改

export function saleReturnOrderUpdate(data) {
  return request({ url: `/sales_return_orders/${data.id}/`, method: 'put', data })
}

// 销售退货详情
export function saleReturnOrderDetail(params) {
  return request({ url: `/sales_return_orders/${params.id}/`, method: 'get', params })
}

// 销售记录录作废
export function saleReturnOrdersVoid(data) {
  return request({ url: `/sales_return_orders/${data.id}/void/`, method: 'post', data })
}

// 销售任务
export function saleTaskList(params) {
  return request({ url: `/sales_tasks/`, method: 'get', params })
}

// 销售任务新增
export function saleTaskCreate(data) {
  return request({ url: `/sales_tasks/`, method: 'post', data })
}


// 销售任务删除
export function saleTaskDestroy(data) {
  return request({ url: `/sales_tasks/${data.id}/`, method: 'delete', data })
}
// 一个月销售榜单
export function saleOrderingMonths(params) {
  return request({ url: `/sales_trends/salesordering_month/`, method: 'get', params })
}



// 销售单发票新增
export function salesInvoicesCreate(data) {
  return request({ url: `/sales_invoices/`, method: 'post', data })
}


// 销售单发票删除

export function salesInvoicesDelete(data) {
  return request({ url: `/sales_invoices/${data.id}/`, method: 'delete', data })
}

// 销售单发票更改


// export function salesInvoicesUpdate(data) {
//   return request({ url: `/sales_invoices/${data.id}/`, method: 'patch', data })
// }


// 销售单发票列表
export function salesInvoicesList(params) {
  return request({ url: `/sales_invoices/${params.id}/`, method: 'get', params })
}

// 销售单发票更改
export function salesInvoicesUpdate(data) {
  return request({ url: `/sales_orders/${data.id}/invoice_update/`, method: 'post', data })
}
// 销售单审核反审核
export function salesBulkStatus(data) {
  return request({ url: `/sales_orders/bulk_status/`, method: 'post', data })
}
