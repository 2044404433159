// import Vue from 'vue'
import VueRouter from "vue-router";
import user from "./user";
import account from "./account";
import manage from "./manage";
import system from "./system";
import report from "./report";
import basicData from "./basicData";
import goods from "./goods";
import assets from "./assets";
import purchasing from "./purchasing";
import sale from "./sale";
import warehouse from "./warehouse";
import finance from "./finance";
import production from "./production";
import team from "./team";
import research from "./research";
import client from "./client";
import afterSale from "./afterSale";
import goodsSample from "./goodsSample";
// Vue.use(VueRouter)
const index = {
  path: "/",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/home",
  children: [
    {
      path: "/home",
      name: "home",
      meta: { title: "首页", permission: "home_page" },
      component: () => import("@/views/home/Home"),
    },
  ],
};

export const routes = [
  index,
  user,
  account,
  manage,
  system,
  report,
  basicData,
  goods,
  assets,
  purchasing,
  sale,
  warehouse,
  finance,
  production,
  team,
  research,
  client,
  afterSale,
  goodsSample,
];

const router  =  new VueRouter({ mode: "history", routes });

export default router


router.beforeEach((to,from,next)=>{

  next();
})


