export default {
    path: "/research",
    name: "research",
    redirect: "/research/research_manage",
    component: () => import("@/layouts/BaseLayout"),
    children: [
        {
            path: "research_manage",
            meta: { title: "研发管理", permission: "research_manage" },
            component: () => import("@/views/research/researchManage/index"),
        }
    ],
};
