export default {
   path: '/goods_sample',
   name: 'goods_sample',
   component: () => import("@/layouts/BaseLayout"),
   redirect: "/finance/classification",
   children: [
    {
      name: 'goods_sample',
      path: "goods_sample",
      meta: { title: "样品赠单记录", permission: "goods_sample" },
      component: () => import("@/views/goodsSample/goodsSample/index"),
    },
    {
      name: 'trial_product',
      path: "trial_product",
      meta: { title: "试用产品", permission: "trial_product" },
      component: () => import("@/views/goodsSample/trialProduct/trialProduct"),
    },
   {
      path: "sample_rate",
      meta: { title: "试用转率", permission: "sample_rate" },
      component: () => import("@/views/goodsSample/sampleRate/sampleRate"),
    },
    {
      name: 'sample_create',
      path: "sample_create",
      meta: { title: "样品赠单", permission: "sample_create" },
      component: () => import("@/views/goodsSample/goodsSampleCreate/index"),
    },
     {
      path: "sample_detail",
      meta: { title: "样品单详情", permission: "sample_detail",notMenu: true },
      component: () => import("@/views/goodsSample/sampleDetail/index"),
    },
    {
      name: 'sample_flow',
      path: "sample_flow",
      meta: { title: "样品库存流水", permission: "sample_flow" },
      component: () => import("@/views/goodsSample/flow/flow"),
    },
   {
      name: 'sample_stock_report',
      path: "sample_stock_report",
      meta: { title: "样品库存报表", permission: "sample_stock_report" },
      component: () => import("@/views/goodsSample/stock_report/stock_report"),
    },
    {
      name: 'sample_outStock',
      path: "sample_outStock",
      meta: { title: "样品出库任务", permission: "sample_outStock" },
      component: () => import("@/views/goodsSample/outStock/index"),
    },
     {
      path: "sample_outStock_create",
      meta: { title: "样品出库创建", permission: "sample_outStock_create" ,notMenu: true},
      component: () => import("@/views/goodsSample/outStockCreate/index"),
    },
     {
      path: "sample_hand_outStock_create",
      meta: { title: "样品手动出库创建", permission: "sample_outStock_create" ,notMenu: true},
      component: () => import("@/views/goodsSample/outStockCreate/outStockCreate.vue"),
    },
   {
      name: 'sample_inventory',
      path: "sample_inventory",
      meta: { title: "样品盘点", permission: "sample_inventory" },
      component: () => import("@/views/goodsSample/inventory/index"),
    },
   {
      name: 'sample_inventory_create',
      path: "sample_inventory_create",
      meta: { title: "样品盘点创建", permission: "sample_inventory_create" ,notMenu: true},
      component: () => import("@/views/goodsSample/inventoryCreate/index"),
    },
   {
      name: 'sample_allocation',
      path: "sample_allocation",
      meta: { title: "样品调拨", permission: "sample_allocation" },
      component: () => import("@/views/goodsSample/allocation/index"),
    },
   {
      name: 'sample_allocation_create',
      path: "sample_allocation_create",
      meta: { title: "样品调拨创建", permission: "sample_allocation_create" ,notMenu: true},
      component: () => import("@/views/goodsSample/allocationCreate/index"),
    },
   {
      path: "sample_outStock_detail",
      meta: { title: "样品通知单详情", permission: "sample_outStock_detail" ,notMenu: true,},
      component: () => import("@/views/goodsSample/outStockDetail/index"),
    },
   {
      path: "sample_outStockRecord_detail",
      meta: {title: "样品记录单详情", permission: "sample_outStockRecord_detail", notMenu: true,},
      component: () => import("@/views/goodsSample/outStockRecordDetail/index"),
    },
   {
      name:'sample_inStock',
      path: "sample_inStock",
      meta: {title: "样品入库", permission: "sample_inStock"},
      component: () => import("@/views/goodsSample/inStock/index"),
    },
     {
      name: 'sample_hand_inStock_create',
      path: "sample_hand_inStock_create",
      meta: {title: "样品手动入库", permission: "sample_hand_inStock_create", notMenu: true,},
      component: () => import("@/views/goodsSample/inStockCreate/inStockCreate"),
    },
    {
      path: "sample_inStock_create",
      meta: {title: "样品入库创建", permission: "sample_inStock_create", notMenu: true,},
      component: () => import("@/views/goodsSample/inStockCreate/index"),
    },
   {
      path: "sample_inStock_detail",
      meta: {title: "样品入库详情", permission: "sample_inStock_create", notMenu: true,},
      component: () => import("@/views/goodsSample/inStockDetail/index"),
    },
   {
      path: "sample_inStockRecord_detail",
      meta: {title: "样品入库记录详情", permission: "sample_inStock_create", notMenu: true,},
      component: () => import("@/views/goodsSample/inStockRecordDetail/index"),
    },
   {
      name: 'sample_purchaseCreate',
      path: "sample_purchaseCreate",
      meta: {title: "样品采购创建", permission: "sample_purchaseCreate"},
      component: () => import("@/views/goodsSample/purchaseCreate/index"),
    },
   {
      name: 'sample_purchaseRecord',
      path: "sample_purchaseRecord",
      meta: {title: "样品采购记录", permission: "sample_purchaseRecord"},
      component: () => import("@/views/goodsSample/purchaseRecord/index"),
    },
   {
      path: "sample_purchaseRecord_detail",
      meta: {title: "样品采购单详情", permission: "sample_purchaseRecord_detail"},
      component: () => import("@/views/goodsSample/purchaseRecordDetail/index"),
    },
   ]
}