export function removeItem(items, item) {
  let index = [...items].findIndex(_item => _item.id == item.id);
  if (index != -1) {
    items.splice(index, 1);
  }
  return items
}
export function removeItemBatchIndexs(items, item) {
  let index = [...items].findIndex(_item => _item.indexs == item.indexs);
  if (index != -1) {
    items.splice(index, 1);
  }
  return items
}
export function removeItemM(items, item) {
  let index = [...items].findIndex(_item => _item.material == item.material);
  if (index != -1) {
    items.splice(index, 1);
  }
  return items
}

export function replaceItem(items, item) {
  let index = [...items].findIndex(_item => _item.id == item.id);
  if (index != -1) {
    items.splice(index, 1, item);
  }
  return items
}

export function insertItem(items, item) {
  let index = [...items].findIndex(_item => _item.id == item.id);
  if (index == -1) {
    items.splice(0, 0, item);
  }
  return items
}

export function insertItemM(items, item) {
  let index = [...items].findIndex(_item => _item.material == item.material);
  if (index == -1) {
    items.splice(0, 0, item);
  }
  return items
}

export function removeItemBatch(items, item) {
  let index = [...items].findIndex(_item => _item.key == item.key);
  if (index != -1) {
    items.splice(index, 1);
  }
  return items
}

export function removeItemSalesOrder(items, item) {
  let index = [...items].findIndex(_item => _item.sales_order_id == item.sales_order_id);
  if(index != -1) {
    items.splice(index, 1);
  }
  return items
} 


export function insertBillsItem(items, item) {
  items.splice(0, 0, item);
  return items
}


export function removeBillsItem(items, item) {
  let index = [...items].findIndex(_item => _item.id == item.id)
  if(index != -1) {
    items.splice(index, 1)
  }
  return items
}

export function removeBillsItemreCord(items, item) {
  let index = [...items].findIndex(_item => _item.billsId == item.billsId)
  if(index != -1) {
    items.splice(index, 1)
  }
  return items
}
// 获取具体高级筛选的参数值
export function searchOption(items, name) {
  return items.filter(_item =>_item.name == name)[0]//查找高级筛选需要无缝滚动的对象
}
// 修改具体高级筛选的参数值
export function changeOption(items, name, value) {
  console.log(items);
  let index = items.findIndex(_item => _item.name == name)
  if(index !=-1 ) {
    items.splice(index, 1, value)
    console.log(1, index);
  }
  return items  
}



// 订单保存功能
export function InvoiceSave(name, data) {
  console.log(window.indexedDB);
}



// 进行数据删除之后，重新渲染以及更新分页索引值

export function getPageIndex({ total_items, select_items, pageSize, currentPage }) {

  // 转为数组
  // 计算批量操作后剩余的总数据量
  const remainingTotalItems = total_items - select_items.length;
  // 计算数据的总数量
  const totalPages = Math.ceil( remainingTotalItems/pageSize )
  // 确保当前页码在总页数范围内
  if (currentPage > totalPages) {
    currentPage = totalPages;
  }
  if(currentPage == 0) {
    currentPage = 1
  }
  // console.log('总页数:'+total_items+'批量出库的页数'+select_items+'剩余数量:'+remainingTotalItems+'请求页数:'+pageSize+'数据页码:'+ totalPages+'当前页码:'+currentPage);

  return currentPage
}

export default { removeItem,removeItemBatchIndexs, removeItemM,  replaceItem, insertItem, insertItemM, removeItemBatch, removeItemSalesOrder, insertBillsItem, removeBillsItem, removeBillsItemreCord, InvoiceSave, getPageIndex, searchOption, changeOption }