export default {
  path: '/user',
  name: 'user',
  component: () => import('@/layouts/UserLayout.vue'),
  children: [
    {
      name:'sign-in',
      path:'sign-in',
      meta: { title: '登录' },
      component:()=> import('@/views/login/sign-in'),
    },
    {
      name:"sign-up",
      path:'sign-up',
      meta: { title: '注册' },
      component:()=> import('@/views/login/sign-up'),
    },
  
    // {
    //   path: 'login',
    //   name: 'login',
    //   meta: { title: '登录' },
    //   component: () => import('@/views/login/Login'),
    // },
    // {
    //   path: 'team/register',
    //   name: 'team/register',
    //   meta: { title: 'Team注册' },
    //   component: () => import('@/views/team/TeamRegister/index'),
    // },
    {
      path: 'set_password',
      name: 'setPassword',
      meta: { title: '设置密码' },
      component: () => import('@/views/setPassword/SetPassword'),
    },
    {
      path: 'loginManage',
      name: 'loginManage',
      meta: { title: '登录' },
      component: () => import('@/views/login/LoginManage'),
    },
  ],
  
}